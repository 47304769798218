<template>
    <v-app>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md4>
                    <v-card class="elevation-12 text-center">
                        <v-card-text>
                            <h1>404</h1>
                            <h3>Look like you're lost</h3>
                            <p>the page you are looking for not avaible!</p>
                            <v-btn to="/">Go to Home</v-btn>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-app>
</template>
<script>
export default {

}
</script>  